
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdTimer,
  MdDashboard,
  MdExtension,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdViewDay,
  MdSchool,
  MdPeople,
  MdLocalLibrary,
  MdPieChart,
  MdNavigation,
  MdPermDataSetting
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from 'utils/bemnames';
import { USER_ROLES } from '../../utils/user-role';
import logoAppsWhite from 'assets/img/logo/logo-apps-white.png';

// const navContents = [
//   { to: '/typography', name: 'typography', exact: false, Icon: MdTextFields },
//   { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
// ];

// const pageContents = [
//   { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
//   {
//     to: '/login-modal',
//     name: 'login modal',
//     exact: false,
//     Icon: MdViewCarousel,
//   },
// ];

const navItem = [
  { to: '/adm_dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/soal', name: 'soal', exact: true, Icon: MdLocalLibrary },
  { to: '/sekolah', name: 'sekolah', exact: true, Icon: MdSchool },
  { to: '/siswa', name: 'akun siswa', exact: true, Icon: MdPeople },
  { to: '/report', name: 'Laporan', exact: true, Icon: MdInsertChart },
  { to: '/config', name: 'Config', exact: true, Icon: MdPermDataSetting },
];

const navComponents = [
  { to: '/kategori_materi', name: 'kategori materi', exact: true, Icon: MdViewDay },
  { to: '/materi', name: 'materi', exact: true, Icon: MdViewDay },
  { to: '/kelas', name: 'kelas', exact: true, Icon: MdViewDay },
  { to: '/mapel', name: 'mapel', exact: true, Icon: MdViewDay },
];

const navItemSekolah = [
  { to: '/sekolah_dashboard', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/jadwal', name: 'Jadwal', exact: true, Icon: MdTimer },
  { to: '/userakun', name: 'Manajemen Akun', exact: true, Icon: MdPeople },
  { to: '/report_sekolah', name: 'Analisa', exact: true, Icon: MdPieChart },
  { to: '/trackingPengerjaan', name: 'Tracking & Report', exact: true, Icon: MdNavigation },
  // { to: '/report_analysis', name: 'Report Analysis', exact: true, Icon: MdInsertChart },  
  // { to: '/kelas', name: 'Pembagian Kelas', exact: true, Icon: MdViewDay },
];



const navItemSiswa = [
  { to: '/userhome', name: 'dashboard', exact: true, Icon: MdDashboard },
  { to: '/siswa/report', name: 'report', exact: true, Icon: MdInsertChart },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: true,
    };
  }


  handleClick = (name) => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    var navItems = [];
    let isAdmin = false;
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      const roles = user.roles;
      if (roles.includes(USER_ROLES.ADMIN)) {
        navItems = navItem;
        isAdmin = true;
      }
      if (roles.includes(USER_ROLES.SEKOLAH)) {
        navItems = navItemSekolah;
      }
      if (roles.includes(USER_ROLES.SISWA)) {
        navItemSiswa.filter(item => item.name === "report").map(obj => obj.to = '/siswa/report/' + user.referenceId + '/' + user.userProfile.userAuthId)
        navItems = navItemSiswa;
      }
    }

    return (
      <aside className={bem.b()} >
        <div className={bem.e('background')} />
        <div className={bem.e('content')}>
          <Navbar className="navbar-brand d-flex logo-pnl">
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logoAppsWhite}
                className="pr-2"
                alt="" style={{ width: '130px' }}
              />
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            {isAdmin && <>
              <NavItem
                className={bem.e('nav-item')}
                onClick={() => this.handleClick('Components')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Master Data</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenComponents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenComponents}>
                {navComponents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
            </>}
          </Nav>
        </div>
      </aside>
    );
  }
}


export default Sidebar;
